import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/index.vue";
import Postgraduate from "../views/postgraduate.vue"; // 研究生申请
import Undergraduate from "../views/undergraduate.vue"; // 本科申请
import About from "../views/about.vue"; // 关于我们
import Success from "../views/success.vue"; // 成功案例
import Improvement from "../views/improvement.vue"; // 背景提升
import Student from "../views/student.vue"; // 背景提升

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/postgraduate",
    name: "postgraduate",
    component: Postgraduate,
  },
  {
    path: "/undergraduate",
    name: "undergraduate",
    component: Undergraduate,
  },
  {
    path: "/success",
    name: "success",
    component: Success,
  },
  {
    path: "/improvement",
    name: "improvement",
    component: Improvement,
  },
  {
    path: "/student",
    name: "student",
    component: Student,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
