import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "aos/dist/aos.css";
import AOS from "aos/dist/aos.js";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { jiance } from "@/utils/common";
const app = createApp(App);
app.use(router).use(AOS).use(ElementPlus).mount("#app");
app.config.globalProperties.$jiance = jiance;
